<template>
  <el-card shadow="never">
    <template #header>
      <div class="card-header">
        <el-page-header @back="goBack">
          <template #content>
            <span class="text-large font-600 mr-3"> 数据统计 </span>
          </template>
        </el-page-header>
      </div>
    </template>
    <div>
      <el-row class="t1" :gutter="10">
        <el-col :span="8">
          <p>今天访问人数</p>
          <p>{{visitNum}}</p>
        </el-col>
        <el-col :span="8">
          <p>注册数量</p>
          <p>{{regNum}}</p>
        </el-col>
        <el-col :span="8">
          <p>发布行程数量</p>
          <p>{{culturesNum}}</p>
        </el-col>
      </el-row>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <div id="detail"></div>
  </el-card>
</template>
<script>
  import { defineComponent, ref } from 'vue'
  import * as echarts from 'echarts'
  import { ElMessage } from 'element-plus'
  import api from '@/api/index.js'
  export default defineComponent({
    setup() {
      const echats = ref({})
      const culturesNum = ref(0)
      const regNum = ref(0)
      const visitNum = ref(0)
      const culturesNumList = ref([])
      const regNumList = ref([])
      const visitNumList = ref([])
      const day = ref([])
      return {
        echats,
        culturesNum,
        regNum,
        visitNum,
        culturesNumList,
        regNumList,
        visitNumList,
        day
      };
    },
    methods: {
      goBack(){
        this.$router.go(-1)
      },
      createEchats(){
        let chartDom = document.getElementById('detail');
        let myChart = echarts.init(chartDom);
        let option;
        let that = this;

        option = {
          xAxis: {
            type: 'category',
            data: that.day
          },
          yAxis: {
            type: 'value'
          },
          grid: {
              show:true,
              left: "5%",
              top: "5%",
              right: "0%",
              bottom: "10%"
          },
          series: [
            {
              data: that.visitNumList,
              type: 'line'
            }
          ]
        };

        myChart.setOption(option);
      },
      getStatistics(){
        let that = this
        api.shopApi.getStatistics({}).then(res => {
            if (res.code === 0) {
              that.culturesNum = res.data.culturesNum
              that.regNum = res.data.regNum
              that.visitNum = res.data.visitNum
              that.culturesNumList = res.data.culturesNumList
              that.regNumList = res.data.regNumList
              that.visitNumList = res.data.visitNumList
              that.day = res.data.day
              that.createEchats()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      }
    },
    mounted () {
      this.getStatistics()
    }
  })
</script>
<style>
#detail{
  width: 100%;
  height: 300px;
}
.t1{
  text-align: center;
}
.t1 p{
  padding-bottom: 5px;
}
.t1 p:nth-child(2){
  font-size: 26px;
  font-weight: bold;
  color: var(--el-menu-active-color);
}
</style>